import {css, html, LitElement, nothing} from "lit";
import {PropertyValues} from "@lit/reactive-element";
import {pxToRem} from "../styles";
import {WlSelected} from "../events/WlSelected";
import {Valuable, ValuableInterface} from "../core/ValuedComponent";

export class WlRange extends Valuable(LitElement) implements ValuableInterface {
    public title: string;
    public priority: string;
    public name: string;
    public form: string | undefined

    static formAssociated = true;

    // @ts-ignore
    private _internals: ElementInternals & IElementInternals | null;

    static get properties() {
        return {
            title: {type: String, reflect: true},
            priority: {type: String, reflect: true},
            name: {type: String, reflect: true},
            disabled: {type: Boolean, reflect: true},
        }
    }

    constructor() {
        super();
        this.title = "";
        this.priority = "";
        this.name = "";
        this.disabled = false;
        this._internals = this.attachInternals();
    }

    clear() {
        super.clear()
        this.getChildren().forEach(child => child.value = null);
    }

    static get styles() {
        return css`
            :host {
                display: flex;
                column-gap: ${pxToRem(4)};
                row-gap: ${pxToRem(6)};
                padding: var(--wl-range-padding, ${pxToRem(10)});
                justify-content: space-between;
                flex-wrap: wrap;
            }

            .title {
                flex-basis: 100%;
                font-size: var(--wl-range-label-font-size, ${pxToRem(16)});
                font-weight: var(--wl-range-label-font-weight, 700);
                color: var(--wl-range-label-color, #000000);
            }
        `
    }

    render() {
        return html`
            ${this.title != '' ? html`<label class="title">${this.title}</label>` : ''}
            <slot @wl-change="${this.updateValue}" @slotchange="${this.updateValue}"></slot>
        `
    }

    update(_changedProperties: PropertyValues) {
        super.update(_changedProperties);
        if (_changedProperties.has("disabled")) {
            this.updateValue()
        }
    }

    private updateValue() {

        if (this.disabled) {
            this._internals.setFormValue(null);
            this.label = "";
            return;
        }

        let children = this.getChildren();
        if (children.length != 2) {
            return;
        }

        let minItem = children[0];
        let maxItem = children[1];

        super.setValue(() => this.getValue(minItem, maxItem), () => this.getLabel(minItem.label, maxItem.label));

        this._internals.setFormValue(this.value);
        this.dispatchEvent(new WlSelected(this.label, this.label));
    }

    private getValue(minItem: WlRangeItem, maxItem: WlRangeItem) {
        if (minItem.value == null && maxItem.value == null) {
            return null;
        }

        let value = new FormData()
        if (minItem.value != null) {
            value.append("min-" + this.name, minItem.value.toString())
        }
        if (maxItem.value != null) {
            value.append("max-" + this.name, maxItem.value.toString())
        }
        return value
    }

    private getLabel(priceMin: string, priceMax: string): string {
        if (priceMin == "" && priceMax == "") {
            return ""
        } else {
            return "" + (priceMin || "") + " - " + (priceMax || "");
        }
    }

    private getChildren() {
        return [...this.childNodes]
            .filter(node => node.nodeType === node.ELEMENT_NODE && node instanceof WlRangeItem)
            .map(node => node as WlRangeItem)
    }
}

export class WlRangeItem extends LitElement {
    public value: number | null;
    public placeholder: string;
    public input: HTMLInputElement | null;
    public title: string;
    public label: string;
    private maxValue: number | null;
    private readonly locale: string;
    private errorMessage: string;

    static get properties() {
        return {
            value: {
                type: String, reflect: true, converter: {
                    fromAttribute(value: string) {
                        if (value == "") {
                            return null
                        }
                        return parseInt(value.replace(".0", ""))
                    },
                }
            },
            placeholder: {type: String, reflect: true},
            title: {type: String, reflect: true},
            maxValue: {type: Number, reflect: true},
            locale: {
                type: String, reflect: true, converter: {
                    fromAttribute(value: string) {
                        return value.replace("_", "-");
                    },
                }
            },
            errorMessage: {type: String, attribute: false},
            label: {type: String, attribute: false},
        }
    }

    constructor() {
        super();
        this.value = null;
        this.placeholder = "";
        this.label = ""
        this.title = "";
        this.maxValue = null;
        this.locale = "th-TH";
        this.input = null;
        this.errorMessage = "";
    }

    static get styles() {
        return css`
            :host {
                flex-basis: calc((100% - 4px) / 2);
            }

            .title {
                font-size: var(--wl-range-item-label-font-size, ${pxToRem(14)});
                font-weight: var(--wl-range-item-label-font-weight, 600);
                color: var(--wl-range-item-label-color, #000000);
            }

            .error-message {
                color: red;
                opacity: 1;
                transition: opacity 1s;
            }

            input {
                max-width: 100%;
                width: calc(100% - 22px);
                height: var(--wl-range-item-height, ${pxToRem(34)});
                padding: 0 ${pxToRem(10)};
                font-size: ${pxToRem(14)};
                color: var(--wl-range-item-color, #000);
                border: 1px solid var(--wl-range-item-border-color, #000);
                border-radius: ${pxToRem(4)};
            }

            input:focus {
                outline: none;
                border: 1px solid var(--wl-range-item-border-color-focus, #000);
            }
        `
    }

    protected render() {
        return html`
            ${this._renderLabel()}
            <input type="text" value="${this.label}" placeholder="${this.placeholder}" 
                   @change="${this.updateValueFromInput}" @keyup="${this.updateValueFromInput}"
            />
        `
    }

    protected update(_changedProperties: PropertyValues) {
        super.update(_changedProperties);
        this.label = this.format(this.value);
    }

    protected updated(_changedProperties: PropertyValues) {
        super.updated(_changedProperties);
        if (this.input != null) {
            this.input!!.value = this.label
        }

        if (_changedProperties.has("value")) {
            if (this.value != null && this.isOutOfLimits(this.value)) {
                debugger;
                this.showError()
                return;
            }

            this.hideError()
            this.dispatchEvent(new CustomEvent("wl-change", {bubbles: true, composed: true}));
        }
    }

    protected firstUpdated(_changedProperties: PropertyValues) {
        this.input = this.renderRoot.querySelector("input")!;
    }

    private _renderLabel() {
        if (this.title == '') {
            return nothing
        }

        if (this.errorMessage == "") {
            return html`<label class="title">${this.title}</label>`;
        }

        return html`<label class="title">${this.title}<span
                class="error-message">${this.errorMessage}</span></label>`;
    }

    clear() {
       this.value = null
    }

    private updateValueFromInput() {
        let parse = this.parse(this.input?.value || "", this.locale);
        this.value = parse;

        if (this.input){
            if (parse){
                this.input.value = this.format(parse);
            } else {
                this.input.value = ""
            }
        }
    }

    private format(value: number | null) {
        if (value == null || Number.isNaN(value)) {
            return ""
        }

        return this.formatNumber(value);
    }

    private isOutOfLimits(value: number) {
        return value < 0 || (this.maxValue && value > this.maxValue);
    }

    private formatNumber(value: number) {
        return value.toLocaleString(this.locale);
    }

    private showError() {
        this.errorMessage = this.createErrorMessage()
        setTimeout(this.hideError, 2000);
    }

    private hideError() {
        this.errorMessage = ""
    }

    private createErrorMessage() {
        let errorMessage = ' (> 0)';
        if (this.maxValue) {
            errorMessage = ` (0-${this.formatNumber(this.maxValue)})`;
        }
        return errorMessage;
    }

    private parse(number: string, locale: string) : number | null {
        const formatter = new Intl.NumberFormat(locale);
        const parts = formatter.formatToParts(12345.6);
        const groupSeparator = parts.find(part => part.type === 'group')!!.value;
        const decimalSeparator = parts.find(part => part.type === 'decimal')!!.value;

        const normalilzedNumber = number
            .replace(new RegExp(`\\${groupSeparator}`, 'g'), '')
            .replace(new RegExp(`\\${decimalSeparator}`), '.')
            .replace(/[^0-9]/g, "");

        let num = parseFloat(normalilzedNumber);
        if (isNaN(num)) {
            return null;
        }
        return num
    }
}
